.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 0.5%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}

.custom-prev-arrow:hover,
.custom-next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


.header {
  z-index: 1000;
  position: relative;
}

.modal-paragraph {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  white-space: pre-line; /* To preserve paragraph breaks */
}

/* Ensure no bullet points are added within carousel cards */
.custom-carousel .ant-card ul {
  list-style-type: none;
  padding-left: 0;
}

.table-row-light {
  background-color: #f9f9f9;
}

.table-row-dark {
  background-color: #f1f1f1;
}

.full-width-video-container {
  width: 100%;
  max-width: 100%; /* Ensure the video doesn't exceed the parent's width */
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Centers the video horizontally */
}

.full-width-video {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensures the video stays responsive */
}

.ant-row.video-analysis-row {
  margin-left: 0 !important;  /* Remove negative margin */
  margin-right: 0 !important; /* Remove negative margin */
  padding: 0 8px;  /* Add padding to maintain the spacing without overflow */
}

@media (max-width: 768px) {
  .card-title {
    font-size: 14px; /* Reduce font size */
  }
  .ant-card {
    margin-bottom: 12px; /* Reduce margin */
  }
  .ant-card-body {
    padding: 10px; /* Reduce padding for smaller screens */
  }
}